








import Vue from "vue"

import KontaktContent from "@/components/KontaktContent.vue"
import MapGoogle from "@/components/MapGoogle.vue"

export default Vue.extend({
  components: {
    KontaktContent,
    MapGoogle
  }
})
