













import Vue from "vue"

export default Vue.extend({
  data() {
    return {
      googleApikey: process.env.VUE_APP_PUBLIC_APIKEY_GOOGLE
    }
  },

  computed: {
    src(): string {
      // https://developers.google.com/maps/documentation/embed/start
      return `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJN-V6xEnbmUcRdkL3M8lnYAU&key=${this.googleApikey}`
    }
  }
})
