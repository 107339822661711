


































































import Vue from "vue"

import BaseLink from "@/components/BaseLink.vue"

import {
  legalLinks as linksLegal,
  newEmailLink,
  newTelephoneLink,
  newSiteCredits
} from "@/model/Link"

export default Vue.extend({
  components: {
    BaseLink
  },

  data() {
    return {
      linksLegal,
      linksContact: [
        newTelephoneLink(),
        newEmailLink()
      ],
      linkSiteCredits: newSiteCredits()
    }
  }
})
